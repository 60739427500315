import React, { useState, useEffect } from 'react';
import './outlineAIprompts.css'; // Importing the CSS file

const OutlineAIPrompts = ({ proposalVolumes, tagForDraft, onSubmitPrompt, outlineHeaders, handleSaveEditor }) => {
  const [sectionPrompts, setSectionPrompts] = useState([]);
  const [currentOutlineVolume, setCurrentOutlineVolume] = useState(null);

  const formatSectionTitle = (section, index) => {
    return `${index + 1}. ${section
      .split('\n')[0] // Only take the first line
      .replace(/\*\*/g, '') // Remove markdown bold markers (**)
      .replace(/^\d*[\.\s]*/, '') // Remove any leading numbers, dots, or spaces
      .trim()}`; // Trim any extra spaces
  };



  
  
  
  
  

  // Find the correct volume based on the tag and initialize the sectionPrompts
  useEffect(() => {
    if (proposalVolumes && tagForDraft) {
      // Find the volume by tag
      const volume = proposalVolumes.find(vol => vol.tag === tagForDraft);

      if (volume) {
        setCurrentOutlineVolume(volume);

        // Check if there are saved prompts, otherwise initialize with empty strings
        const savedPrompts = volume.AiOutlinePromptMessage || [];
        const initializedPrompts = savedPrompts.length === volume.sections.length 
          ? savedPrompts 
          : new Array(volume.sections.length).fill('');

        setSectionPrompts(initializedPrompts);
      }
    }
  }, [proposalVolumes, tagForDraft]);

  // Handle input change for each section prompt and submit after every change
  const handleInputChange = (index, value) => {
    const newPrompts = [...sectionPrompts];
    newPrompts[index] = value;
    setSectionPrompts(newPrompts);

    // Automatically submit the prompts after each change
    onSubmitPrompt(newPrompts); // Pass the updated array of prompts back to the parent component
  };

  return (
    <div className="ai-prompt-container" id="step26">
      <div className="ai-prompt-refresh-button-container">
        <p className="ai-prompt-title-optional">(OPTIONAL)</p>
        <h3 className="ai-prompt-title">Provide Guidance for Each Section</h3>
        <button
          className="ai-prompt-refresh-button"
          onClick={handleSaveEditor}
          title="Click to refresh"
        >
          <img src="/images/icons/refresh.svg" alt="Refresh" className="refresh-icon" />
          <span className="refresh-tooltip">Click to refresh</span>
        </button>
      </div>
        <p className="ai-prompt-instructions">
          Please provide specific details or instructions for each section below to guide the AI in generating the content. 
          For example, you can specify what product to highlight, key benefits to emphasize, or any important points to cover.
        </p>
      <form className="ai-prompt-form">
        
        {currentOutlineVolume && currentOutlineVolume.sections.map((section, index) => (
          <div key={index} className="ai-prompt-section">
            <label className="ai-prompt-label" htmlFor={`section-prompt-${index}`}>
              {formatSectionTitle(section, index)}
            </label>
            <textarea
              className="ai-prompt-textarea"
              id={`section-prompt-${index}`}
              value={sectionPrompts[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
              placeholder={`Optionally provide specific instructions for our AI to follow for this section.`}
              rows={3}
            />
          </div>
        ))}
      </form>
    </div>
  );
};

export default OutlineAIPrompts;
