import React from "react";

const ConfirmRemoveVolumeModal = ({ onConfirm, onCancel, volumeIndex, proposalStructureStage }) => {
  return (
    <div className="govex-confirm-remove-volume-modal">
      <div className="govex-modal-overlay"></div>
      <div className="govex-modal-content">
        <h3>Confirm Deletion</h3>
        <p>Are you sure you want to remove this volume? This action cannot be undone.</p>
        <div className="govex-modal-buttons">
          <button className="govex-btn-confirm" onClick={onConfirm}>
            Yes, Remove
          </button>
          <button className="govex-btn-cancel" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRemoveVolumeModal;
