import React, { useRef, useEffect, useState } from 'react';
import './Home.css';
import * as THREE from 'three';
import Slider from 'react-slick';
import ProductHighlight from './ProductHighlight';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import SimpleModal from './SimpleModal';
import NewsletterPopup from "./NewsletterPopup";

// Detect environment
const ENV = process.env.NODE_ENV; // 'test' or 'live'
const BASE_URL = window.location.hostname === 'localhost' 
  ? process.env.REACT_APP_TEST_URL 
  : process.env.REACT_APP_LIVE_URL;

function Home() {
  const containerRef = useRef();

  // Example partner logos & links
  const partnerLogos = [
    '/images/RFP.png',
    '/images/RFI.png',
    '/images/RFQ.png',
    '/images/SBIR.png',
    '/images/STTR.png',
    '/images/BAA.png',
    '/images/FOA.png',
  ];
  const partnerLinks = [
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/',
    'https://govexai.com/'
  ];
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    useTransform: true
  };
  const [activeStep, setActiveStep] = useState(1);

  const steps = [
    {
      id: 1,
      title: "Organize Opportunities",
      description: "Gather and sort the opportunities you want to pursue.",
      previewUrl: "/images/test2.gif" // Replace with your actual GIF path
    },
    {
      id: 2,
      title: "Upload Reference Material",
      description: "Provide relevant documents or past proposals for context.",
      previewUrl: "/images/test1.gif"
    },
    {
      id: 3,
      title: "Analyze Solicitations",
      description: "GovEx AI reviews each solicitation to understand requirements.",
      previewUrl: "/images/test1.gif"
    },
    {
      id: 4,
      title: "Instruct the AI",
      description: "Specify scope, goals, and special instructions for the draft.",
      previewUrl: "/images/test1.gif"
    },
    {
      id: 5,
      title: "Get Your First Draft",
      description: "In minutes, GovEx AI generates a structured, compliant proposal.",
      previewUrl: "/images/test1.gif"
    }
  ];


  // Intersection Observers
  const [featuresSectionTitleRef, featuresSectionTitleInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionSpanRef, featuresSectionSpanInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionPRef, featuresSectionPInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentImageDivRef, featuresSectionContentImageDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentTextH1Ref, featuresSectionContentTextH1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentTextPRef, featuresSectionContentTextPInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDivHighlights1Ref, featuresSectionContentDivHighlights1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDivHighlights2Ref, featuresSectionContentDivHighlights2InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDiv2TextH1Ref, featuresSectionContentDiv2TextH1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDiv2TextPRef, featuresSectionContentDiv2TextPInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresSectionContentDiv2ImageDivRef, featuresSectionContentDiv2ImageDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [newCapabilitiesSectionRef, newCapabilitiesSectionInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [SBIRImageDivRef, SBIRImageDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const [testimonialsSectionTextDivRef, testimonialsSectionTextDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [testimonialsSectionContentTextDiv1Ref, testimonialsSectionContentTextDiv1InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [testimonialsSectionContentTextDiv2Ref, testimonialsSectionContentTextDiv2InView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [pricingSectionTextDivRef, pricingSectionTextDivInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [pricingCardsContainerRef, pricingCardsContainerInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const navigate = useNavigate();
  const location = useLocation();

  // Modal example
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  // Calendly / Payment-specific
  const [pricingPeriod, setPricingPeriod] = useState('monthly');
  const prices = {
    basic: {
      monthly: 750,
      annual: 8250,
    },
    innovator: {
      monthly: 999,
      annual: 10000,
    },
    base: {
      monthly: 99.99,
      annual: 999.99,
    },
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('success') === 'true') {
      setShowModal(true);
    }
  }, [location]);

  const getPartnerLink = (index) => {
    return partnerLinks[index];
  };

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      navigate('/');
      setTimeout(() => {
        const newSection = document.getElementById(sectionId);
        if (newSection) {
          newSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
  };

  return (
    <div className="home-div">
      {showModal && <SimpleModal onClose={handleCloseModal} />}

      {/* -------------------- HERO SECTION -------------------- */}
      <section className="home-section">
        <div className="hero-section">
          <div className="home-content">
            <div className="home-text">
              <h1>
                <span>Revolutionize</span> Your Proposal Process with AI
              </h1>
              <p>
                GovEx AI transforms even the most complex solicitations—SBIRs, STTRs, BAAs, RFPs—into 
                ready-to-edit drafts in minutes. Streamline compliance, accelerate drafting, and give 
                your team more time for high-level strategy.
              </p>
              <Link to="/govEx-proposal">
                <button
                  className="explore-button"
                  onClick={(e) => scrollToSection(e, 'Pricing')}
                >
                  Book a Demo
                </button>
              </Link>
            </div>
            <div className="image-container">
              <div className="image-container-inside in">
                <img
                  src="/images/Dashboard-screenshot-2.png"
                  alt="Screenshot of GovEx AI Dashboard"
                />
              </div>
            </div>
          </div>

          {/* PARTNERS / COMPATIBLE TOOLS SECTION */}
          <div className="our-partners-section">
            <h2 className="our-partners-title">Works well with</h2>
            <div className="slider-container">
              <Slider {...settings}>
                {partnerLogos.map((logo, index) => (
                  <div key={index} className="slide-item">
                    <a
                      href={getPartnerLink(index)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={logo}
                        alt={`Partner Logo ${index}`}
                        className="partner-logo"
                      />
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <div className="how-it-works-section">
        <h2 className="how-it-works-title">How GovEx AI Works</h2>
        <p className="how-it-works-intro">
          Getting started is simple. Our AI engine ingests your solicitation documents 
          and automatically produces a structured, compliant outline...
        </p>

        <div className="how-it-works-container">
          {/* LEFT COLUMN: Vertical Steps */}
          <div className="how-it-works-steps">
            {steps.map(step => {
              const isActive = activeStep === step.id;
              return (
                <div
                  key={step.id}
                  className={`how-it-works-step ${isActive ? "active-step" : ""}`}
                  onClick={() => setActiveStep(step.id)}
                >
                  <h3>
                    {step.id}. {step.title}
                  </h3>
                  <p>{step.description}</p>
                </div>
              );
            })}
          </div>

          {/* RIGHT COLUMN: GIF Previews */}
          <div className="how-it-works-preview">
            {steps.map(step => (
              <div
                key={step.id}
                className={`gif-preview ${activeStep === step.id ? "active" : ""}`}
                style={{ backgroundImage: `url(${step.previewUrl})` }}
              />
            ))}
          </div>
        </div>
      </div>


      {/* -------------------- USE CASES SECTION (NEW) -------------------- */}
      <section className="use-cases-section" id="Use-Cases">
        <div className="use-cases-content">
          <h2>Use Cases Tailored for <span>You</span></h2>
          <p>
            Whether you’re in government contracting, nonprofit grant writing, or 
            tech startups pursuing SBIR funding, GovEx AI has you covered.
          </p>
          <div className="use-cases-grid">
            <div className="use-case-item">
              <img src="/images/use-case-1.png" alt="Government Contracting" />
              <h4>Government Contractors</h4>
              <p>
                Shorten your response time to RFPs, BAAs, and more. 
                Automatic compliance checks mean fewer headaches.
              </p>
            </div>
            <div className="use-case-item">
              <img src="/images/use-case-2.png" alt="Nonprofits" />
              <h4>Nonprofit Grant Writers</h4>
              <p>
                Quickly draft high-quality grant proposals for FOAs, 
                ensuring each requirement is addressed.
              </p>
            </div>
            <div className="use-case-item">
              <img src="/images/use-case-3.png" alt="Startups and SBIRs" />
              <h4>Startup Innovators</h4>
              <p>
                Perfect for SBIR/STTR applications. 
                Cut down on time-consuming documentation, and focus on innovation.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* -------------------- FEATURES SECTION -------------------- */}
      <section className="features-section" id="Proposal-Writer">
        <div
          className={`features-section-text-div ${
            featuresSectionTitleInView ? 'visible' : ''
          }`}
          ref={featuresSectionTitleRef}
        >
          <h1 className="features-section-title">The Future of Proposal Generation</h1>
        </div>
        <span
          ref={featuresSectionSpanRef}
          className={`features-section-span ${
            featuresSectionSpanInView ? 'visible' : ''
          }`}
        >
          AI-Driven, Accurate, and Agile
        </span>

        <p
          ref={featuresSectionPRef}
          className={`features-section-p ${
            featuresSectionPInView ? 'visible' : ''
          }`}
        >
          Revolutionize your proposal process—our AI generator tailors each document to 
          your specific needs, ensuring a personalized touch every time.
        </p>

        <div className="features-section-content-div">
          <div
            ref={featuresSectionContentImageDivRef}
            className={`features-section-content-image-div ${
              featuresSectionContentImageDivInView ? 'visible' : ''
            }`}
          >
            <img
              src="/images/dashboard-headers-PC-1.png"
              alt="GovEx AI dashboard"
            />
          </div>
          <div className="features-section-content-text-div">
            <h1
              ref={featuresSectionContentTextH1Ref}
              className={`features-section-content-text-h1 ${
                featuresSectionContentTextH1InView ? 'visible' : ''
              }`}
            >
              <span>Fast-Track</span> Your Proposals
            </h1>
            <p
              ref={featuresSectionContentTextPRef}
              className={`features-section-content-text-p ${
                featuresSectionContentTextPInView ? 'visible' : ''
              }`}
            >
              In today's fast-paced environment, time is money—and efficiency is everything. 
              Our AI Proposal Writer cuts out tedious hours, condensing the proposal writing 
              process into mere minutes. Free your team to focus on strategy, not paperwork.
            </p>
            <div className="features-section-content-div-in-text-div">
              <div
                ref={featuresSectionContentDivHighlights1Ref}
                className={`features-section-content-div-highlights ${
                  featuresSectionContentDivHighlights1InView ? 'visible' : ''
                }`}
              >
                <div className="features-section-content-div-icon">
                  <img src="/images/db-icon.png" alt="Database Icon" />
                </div>
                <h4>Personalized, Secure, Manageable Database</h4>
                <p>
                  Continuously refine and personalize the AI's proposal generation by 
                  adding or removing files from your private database.
                </p>
              </div>
              <div
                ref={featuresSectionContentDivHighlights2Ref}
                className={`features-section-content-div-highlights ${
                  featuresSectionContentDivHighlights2InView ? 'visible' : ''
                }`}
              >
                <div className="features-section-content-div-icon">
                  <img
                    src="/images/ai-dashboard-icon.png"
                    alt="AI Dashboard Icon"
                  />
                </div>
                <h4>AI Assistant: Your Partner in Rapid Editing</h4>
                <p>
                  Transform your proposals with an AI Assistant that rapidly enhances 
                  content for clarity, compliance, and quality.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="features-section-content-div-2">
          <div
            ref={featuresSectionContentDiv2TextH1Ref}
            className={`features-section-content-text-div-2 ${
              featuresSectionContentDiv2TextH1InView ? 'visible' : ''
            }`}
          >
            <h1>
              Tailor-made <span>Proposals</span>, for Your Business
            </h1>
            <p
              ref={featuresSectionContentDiv2TextPRef}
              className={`${
                featuresSectionContentDiv2TextPInView ? 'visible' : ''
              }`}
            >
              Experience personalized proposal creation, meticulously crafted by AI 
              to align precisely with your unique business needs and goals.
            </p>
          </div>

          <div
            ref={featuresSectionContentDiv2ImageDivRef}
            className={`features-section-content-image-div-2 ${
              featuresSectionContentDiv2ImageDivInView ? 'visible' : ''
            }`}
          >
            <img
              src="/images/manage_db_screenshot_blue_corner.png"
              alt="Manage Database Screenshot"
            />
          </div>
        </div>

        <div
          className={`features-section-new-capabilities-wrapper ${
            newCapabilitiesSectionInView ? 'visible' : ''
          }`}
          ref={newCapabilitiesSectionRef}
        >
          <div className="features-section-new-capabilities-image">
            <img
              src="/images/Comparison_transparent.png"
              alt="New Capabilities"
            />
          </div>
          <div className="features-section-new-capabilities">
            <h2>
              Why <span>GovEx AI</span>?
            </h2>
            <p>
              We’re the first platform to support not just RFPs, RFIs, and RFQs, but also 
              SBIRs, FOAs, BAAs, and STTRs.
            </p>
            <ul>
              <li>
                <strong>SBIR (Small Business Innovation Research):</strong> Our AI pinpoints 
                and structures all requirements—from topics to deadlines—so you can draft 
                a compliant SBIR proposal faster.
              </li>
              <li>
                <strong>FOA (Funding Opportunity Announcements):</strong> Identify and respond 
                to funding opportunities with laser-precision.
              </li>
              <li>
                <strong>BAA (Broad Agency Announcements):</strong> Produce BAAs that meet 
                exact agency requirements—all in record time.
              </li>
              <li>
                <strong>STTR (Small Business Technology Transfer):</strong> Seamlessly 
                facilitate partnerships between small businesses and research institutions 
                for technological breakthroughs.
              </li>
            </ul>
          </div>
        </div>

        <div
          ref={SBIRImageDivRef}
          className={`SBIRImageDiv ${SBIRImageDivInView ? 'visible' : ''}`}
        >
          <img
            src="/images/SBIR-ss.png"
            alt="SBIR Example Screenshot"
          />
        </div>
      </section>

      {/* -------------------- TESTIMONIALS SECTION -------------------- */}
      <section className="testimonials-section" id="Customer-Stories">
        <div
          ref={testimonialsSectionTextDivRef}
          className={`testimonials-section-text-div ${
            testimonialsSectionTextDivInView ? 'visible' : ''
          }`}
        >
          <h1 className="testimonials-section-title">
            Hear what our clients say about GovEx AI
          </h1>
        </div>

        <div className="testimonials-section-content-div">
          <div
            ref={testimonialsSectionContentTextDiv1Ref}
            className={`testimonials-section-content-text-div ${
              testimonialsSectionContentTextDiv1InView ? 'visible' : ''
            }`}
          >
            <div className="testimonials-section-content-text-profile-div">
              <img src="/images/stock-1.jpg" alt="Client Testimonial Headshot" />
            </div>
            <p>
              “The GovEx AI Proposal Writer has significantly streamlined our 
              proposal process. It's user-friendly and remarkably efficient, 
              turning hours of work into just a few minutes.”
            </p>
            <div className="testimonial-section-profile-name-title">
              <span>Justin Lohneis</span>
              <p>Account Executive</p>
            </div>
          </div>

          <div
            ref={testimonialsSectionContentTextDiv2Ref}
            className={`testimonials-section-content-text-div ${
              testimonialsSectionContentTextDiv2InView ? 'visible' : ''
            }`}
          >
            <div className="testimonials-section-content-text-profile-div">
              <img src="/images/neelie.jpg" alt="Client Testimonial Headshot" />
            </div>
            <p>
              “GovEx AI not only simplifies the start of our proposal process but its 
              compliance matrix feature is a game changer. It sets the foundation, 
              freeing us up to focus on refining and perfecting the final submission.”
            </p>
            <div className="testimonial-section-profile-name-title">
              <span>Neelie Rosson</span>
              <p>Proposal Coordinator</p>
            </div>
          </div>
        </div>
      </section>

      {/* -------------------- FAQ SECTION (NEW) -------------------- */}
      <section className="faq-section" id="FAQ">
        <div className="faq-section-content">
          <h2>Frequently Asked <span>Questions</span></h2>
          <div className="faq-items">
            <div className="faq-item">
              <h4>What makes GovEx AI different from other proposal tools?</h4>
              <p>
                GovEx AI supports a broad range of solicitation types—including 
                RFPs, RFIs, SBIRs, and more—while offering a private, customizable 
                database and a powerful AI editor that saves you hours of time.
              </p>
            </div>
            <div className="faq-item">
              <h4>Is my data secure?</h4>
              <p>
                Absolutely. We take data privacy seriously. Your uploaded documents 
                and database entries are encrypted and stored securely in compliance 
                with industry standards.
              </p>
            </div>
            <div className="faq-item">
              <h4>Can I export my drafts into my preferred format?</h4>
              <p>
                Yes. We offer multiple export options (Word, PDF, etc.) so you can 
                easily integrate AI-generated content into your final proposal documents.
              </p>
            </div>
            <div className="faq-item">
              <h4>Do you offer enterprise or custom solutions?</h4>
              <p>
                We do. For large teams or specialized needs, reach out via our contact 
                form. We’ll work with you to tailor GovEx AI to your organization's 
                requirements.
              </p>
            </div>
          </div>
        </div>
      </section>

      <NewsletterPopup />

      {/* -------------------- PRICING & CALENDLY SECTION -------------------- */}
      <section className="pricing-section-calendar" id="Pricing">
        <div
          ref={pricingSectionTextDivRef}
          className={`pricing-section-text-div-calendar ${
            pricingSectionTextDivInView ? 'visible' : ''
          }`}
        >
          <h2 className="pricing-section-title">
            Experience the <span className="pricing-section-smart">GovEx</span> Difference
          </h2>
          <span className="pricing-section-span">Ready to elevate your operations?</span>
        </div>

        {/*
          UNCOMMENT IF YOU WANT THE PRICING CARDS
          <div className="pricing-period-tabs">
            <button
              className={`billing-cycle-option ${pricingPeriod === 'monthly' ? 'selected' : ''}`}
              onClick={() => setPricingPeriod('monthly')}
            >
              Monthly
            </button>
            <button
              className={`billing-cycle-option ${pricingPeriod === 'annual' ? 'selected' : ''}`}
              onClick={() => setPricingPeriod('annual')}
            >
              Annual
            </button>
          </div>

          <div
            ref={pricingCardsContainerRef}
            className={`pricing-cards-container ${
              pricingCardsContainerInView ? 'visible' : ''
            }`}
          >
            // Example Pricing Card
          </div>
        */}

        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/govexai/30min"
          style={{ minWidth: '100%', height: '700px' }}
          id="Contact"
        />
      </section>
    </div>
  );
}

export default Home;
