// Footer.js
import React from 'react';
import './Footer.css';
import { NavLink, useLocation } from 'react-router-dom'; // Import NavLink for routing
import { useNavigate } from 'react-router-dom';

function Footer() {

  const location = useLocation();
  const isDashboard = location.pathname === '/';
  const isDashboardSBIR = location.pathname === '/dashboard-grants' || location.pathname === '/dashboard';
  const navigate = useNavigate();

  const scrollToSection = (event, sectionId) => {
      event.preventDefault(); // Prevent default anchor link behavior
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        // Fallback for when the section ID is not found; navigate to the homepage
        // This is useful if the Navbar is used on other pages and the target section doesn't exist there
        navigate('/'); // Navigate to the homepage first
        setTimeout(() => {
          // Wait for the navigation to complete and the page to load
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 0);
      }
    };

  if (isDashboard || isDashboardSBIR) return null;
  return (
    <div className="wrapper">
      <footer className="footer">
          <div className="logo">
            <a href="/" /* Add the link to your homepage here */>
              <img src="/images/govex_name_transparent.png" alt="Your Tech Company Logo" />
            </a>
          </div>
          <div className="footer-links">
            <ul>
                <li className="mainNavLink"><a href="#Proposal-Writer" className="mainNavLinkText" onClick={(e) => scrollToSection(e, 'Proposal-Writer')}>Proposal Writer</a></li>
                <li className="mainNavLink"><a href="#Customer-Stories" className="mainNavLinkText" onClick={(e) => scrollToSection(e, 'Customer-Stories')}>Customer Stories</a></li>
                <li className="mainNavLink"><NavLink to="/contact" className="mainNavLinkText">Contact</NavLink></li>

            </ul>
          </div>
      </footer>
      <p className="footer-copyright">&copy; {new Date().getFullYear()} GovEx AI LLC All rights reserved.</p>
    </div>
  );
}

export default Footer;
